import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonGroup = ({ children, mods, mix, direction, className: classNameTail }) => {
    const { className } = useMods(ButtonGroup, { mix, mods: { ...mods, direction } }, true);

    return (
        <div
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
        >
            { children }
        </div>
    );
};

ButtonGroup.displayName = 'Button-Group';

ButtonGroup.propTypes = {
    children: PropTypes.node,
    direction: PropTypes.string,
    mix: PropTypes.object,
    mods: PropTypes.object
};

ButtonGroup.defaultProps = {
    mix: {},
    mods: {}
};

export default ButtonGroup;
