import css from '@utils/css';
import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const FooterAction = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(FooterAction, { mix, mods });

    return (
        <div
            className={ css(className, classNameTail) }
        >
            { children }
        </div>
    );
};

FooterAction.displayName = 'Footer-Action';

FooterAction.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

FooterAction.defaultProps = {
    mix: {},
    mods: {}
};

export default FooterAction;
