import css from '@utils/css';
import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const SwitchToTraqqWideText = ({ mods }) => {
    const { className } = useMods(SwitchToTraqqWideText, { mods });

    return (
        <div
            className={ className }
        >
            <img
                src={ 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQyIiBoZWlnaHQ9IjI0OCIgdmlld0JveD0iMCAwIDM0MiAyNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zMzkuMzMxIDc5Ljk5MjJDMzM3LjkyIDQzLjA4NTMgMzA4LjAwMiAxMy43NzY4IDI2OS4yNjggMTEuMzU2TDkwLjE1NyAwLjE2MTc4OUM0NS42MTYgLTIuNjIxOTYgNi40ODUzMiAzMC44Njk3IDQuNTcwNjIgNzMuNDE0NkwwLjA3MzA4OTYgMTczLjM0OUMtMS45Mjg3MSAyMTcuODI5IDM3LjQ2NTEgMjUyLjU4OSA4NC4xMTYxIDI0Ny41MDVMMjcwLjM0MiAyMjcuMjEzQzMxMS44OTcgMjIyLjY4NSAzNDMuNDU5IDE4Ny45NjQgMzQxLjk0OCAxNDguNDM5TDMzOS4zMzEgNzkuOTkyMloiIGZpbGw9IiNGOTU4NDIiLz4KPC9zdmc+Cg==' }
                alt=''
            />

            <span
                className={css(`
                text-[98px] font-[900] leading-[84px]
                480:(text-[114px] leading-[100px])
                768:(text-[150px] leading-[134px])
                `)}
            >
                <strong>30%<br />off</strong>
            </span>
        </div>
    );
};

SwitchToTraqqWideText.displayName = 'Switch-To-Traqq-Wide__Text';

SwitchToTraqqWideText.propTypes = {
    mods: PropTypes.object
};

SwitchToTraqqWideText.defaultProps = {
    mods: {}
};

export default SwitchToTraqqWideText;
