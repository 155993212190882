import Button from '@components/UI/Button';
import Link from '@components/UI/Link';
import RenderIf from '@utils/renderIf';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import cookiePNG from './cookie.png';

const CookieAccept = () => {
    const { isReady } = useRouter();

    const [visible, setVisible] = useState(false);

    const cookie = { name: 'CookieAccept', value: 'true', path: '/' };

    const accept = () => {
        Cookies.set(cookie.name, cookie.value, { path: cookie.path });
        setVisible(false);
    };

    useEffect(() => {
        if (isReady && Cookies.get('CookieAccept') === undefined) {
            setVisible(true);
        }
    }, [isReady]);

    return (
        <RenderIf isTrue={ visible }>
            <section className='sticky bottom-0 z-[666]'>
                <div className='
                    absolute bottom-0 right-0 bg-white rounded-t-[12px] p-6 gap-y-6 w-full flex flex-col
                    cookie-shadow
                    768:w-[340px] 768:bottom-8 768:right-8 768:rounded-[12px]
                    1024:bottom-12 1024:right-12
                    '>
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-row gap-3 items-center'>
                            <img
                                src={ cookiePNG.src }
                                alt="Cookie consent"
                                width={ 40 }
                                height={ 40 }
                            />
                            <p className='paragraph paragraph_lg font-[700]'>Hey, we use cookies ;)</p>
                        </div>
                        <p className='paragraph paragraph_xs !text-neutral-700'>
                            Traqq uses cookies to improve content and ensure you get the best experience on our website. By continuing to browse our website, you agree to our <Link mods={ { underline: true } } href='/legal/privacy'>privacy policy</Link>.
                        </p>
                    </div>
                    <Button
                        variant='primary'
                        onClick={ accept }
                        className='!px-6 !py-2.5 w-full basis-[content] self-center'
                        buttonProps={ { 'tabIndex': 0 } }
                    >
                        OK
                    </Button>
                </div>
            </section>
        </RenderIf>
    );

};

export default CookieAccept;
