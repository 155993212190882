import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const Divider = ({ mods, variant, style }) => {
    const { className } = useMods(Divider, { mods: { ...mods, style, variant } }, true);

    return (
        <div
            className={ className }
        />
    );
};

Divider.displayName = 'Divider';

Divider.propTypes = {
    mods: PropTypes.object,
    style: PropTypes.string,
    variant: PropTypes.string
};

Divider.defaultProps = {
    mods: {},
    style: 'solid',
    variant: 'horizontal'
};

export default Divider;
