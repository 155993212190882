import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const Footer = ({ children, mods, mix }) => {
    const { className } = useMods(Footer, { mix, mods });

    return (
        <footer
            className={ className }
        >
            { children }
        </footer>
    );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

Footer.defaultProps = {
    mix: {},
    mods: {}
};

export default Footer;
