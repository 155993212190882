import GetOnTraqqBg from '@components/Ads/GetOnTraqq/GetOnTraqqBg';
import Section from '@components/Section';
import Button from '@components/UI/Button';
import Container from '@components/UI/Container';

import React from 'react';

const GetOnTraqqLayout = () => (
    <div
        className='get-on-traqq-layout'
    >
        <Section
            mods={{
                'bg-neutral': 900
            }}
            className='!mb-0 py-113p md:py-100p 1024:py-[134px]'
        >
            <GetOnTraqqBg mix={ { 'get-on-traqq-layout__bg':true } } />

            <Container
                className='relative flex flex-col items-center'
            >
                    <p
                        className='heading heading_text-center heading_white h1'
                    >
                        Ready to get on Traqq?
                    </p>

                    <Button
                        tag='a'
                        type='link'
                        href={`${process.env.NEXT_PUBLIC_APP_TRAQQ_URL}/signup`}
                        variant='primary'
                        size='lg'
                        className='mt-6 md:mt-12'
                    >
                        Start Now
                    </Button>
            </Container>
        </Section>
    </div>
);

export default GetOnTraqqLayout;
