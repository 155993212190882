import SwitchToTraqqText from '@components/Ads/SwitchToTraqqWideText';
import Grid from '@components/Grid';
import Info from '@components/Info';
import Section from '@components/Section';
import Button from '@components/UI/Button';
import Container from '@components/UI/Container';
import Heading from '@components/UI/Typography/Heading';
import Paragraph from '@components/UI/Typography/Paragraph';

import React from 'react';

const SwitchToTraqqLayout = () => (
    <div
        className='switch-to-traqq-layout'
    >
        <Section
            mods={ {
                'bg-neutral': 900
            } }
            className='!mb-0 py-8 480:py-12 1024:py-18 1280:py-[86px]'
        >
            <Container>
                <Grid
                    sm={ 1 }
                    md={ 2 }
                    items='center'
                    gx={ 6 }
                    gy={ 8 }
                >
                    <Info
                        gap={ 4 }
                        mods={ { md: 'center' } }
                    >
                        <p
                            className='h2 heading heading_white'
                        >
                            Get a competitor discount
                        </p>

                        <Paragraph
                            tag='p'
                            size='lg'
                            color='white'
                        >
                            If you use another time tracker or employee monitoring <br className='hidden 1280:inline'/> software, <br className='hidden 768:max-1024:inline'/>
                            you can get Traqq <br className='hidden 480:max-768:inline'/> at up to 30% off.
                        </Paragraph>

                        <Button
                            tag='a'
                            type='link'
                            href='/switch-to-traqq-discount'
                            variant='primary'
                            size='lg'
                        >
                            Get a discount
                        </Button>
                    </Info>

                    <SwitchToTraqqText
                        bg='narrow'
                        mods={ { order: 'first' } }
                    />
                </Grid>
            </Container>
        </Section>
    </div>
);

export default SwitchToTraqqLayout;
