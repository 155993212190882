export default {
    green: {
        100: '#EBFAF4',
        300: '#ABEAD3',
        500: '#45D7A2',
        650: '#32CD95',
        700: '#33CA94',
        900: '#20C288',
    },
    neutral: {
        100: '#F9F9FD',
        300: '#EFF0F9',
        400: '#DFE1EA',
        450: '#D0D2DC',
        500: '#B0B3C1',
        600: '#7F818F',
        700: '#585668',
        900: '#3D3B4F'
    },
    red: {
        100: '#FFEBE8',
        300: '#FFC8C0',
        500: '#FF7664',
        700: '#FF604A',
        900: '#F95842'
    },
    violet: {
        100: '#F2F3FF',
        300: '#DEDFFF',
        500: '#8C90E3',
        600: '#868BFF',
        700: '#676BD2',
        800: '#7176FB',
        900: '#575BBE'
    },
    yellow: {
        100: '#FFF8DD',
        300: '#FFEFB7',
        500: '#FFD952',
        700: '#FFCD3D',
        900: '#FFC621',
    },
    blue: {
        100: '#CAD5EA',
        500: '#5F94FF',
        700: '#3E8DF6',
    }
};
