import css from '@utils/css'
import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const Grid = ({
    children,
    mods,
    mix,
    cols,
    xs,
    sm,
    md,
    lg,
    xl,
    items,
    justify,
    g, // gap
    gx, // gap top|bottom
    gy, // gap left|right
    p, // padding
    pt, // padding-top
    pb, // padding-bottom
    px, // padding top|bottom
    py, // padding left|right,
    className: classNameTail
}) => {
    const { className } = useMods(Grid, { mix , mods: { ...mods, cols, g, gx, gy, items, justify, lg, md, p, pb, pt, px, py, sm, xl, xs } });
    return (
        <div
            className={ css(className, classNameTail) }
        >
            { children }
        </div>
    );
};

Grid.displayName = 'Grid';

Grid.propTypes = {
    children: PropTypes.node,
    cols: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    g: PropTypes.number,
    gx: PropTypes.number,
    gy: PropTypes.number,
    items: PropTypes.string,
    justify: PropTypes.string,
    lg: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    md: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    mix: PropTypes.object,
    mods: PropTypes.object,
    p: PropTypes.number,
    pb: PropTypes.number,
    pt: PropTypes.number,
    px: PropTypes.number,
    py: PropTypes.number,
    sm: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    xl: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    xs: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

Grid.defaultProps = {
    mix: {},
    mods: {}
};

export default Grid;
