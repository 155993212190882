import css from '@utils/css';
import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const FooterNav = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(FooterNav, { mix, mods });

    return (
        <ul
            className={ css(className, classNameTail) }
        >
            { children }
        </ul>
    );
};

FooterNav.displayName = 'Footer-Nav';

FooterNav.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

FooterNav.defaultProps = {
    mix: {},
    mods: {}
};

export default FooterNav;
