import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const FooterNavItem = ({ children, mods, mix }) => {
    const { className } = useMods(FooterNavItem, { mix, mods });

    return (
        <li
            className={ className }
        >
            { children }
        </li>
    );
};

FooterNavItem.displayName = 'Footer-Nav__Item';

FooterNavItem.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

FooterNavItem.defaultProps = {
    mix: {},
    mods: {}
};

export default FooterNavItem;
