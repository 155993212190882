import useMods from '@hooks/useMods';

import logoDark from '@images/logoDark.svg';
import logoLight from '@images/logoLight.svg';
import logoWithoutText from '@images/logoWithoutText.svg';

import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ mods, type, width, className: classNameTail }) => {
    const { className } = useMods(Logo, { mods });

    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
            src={ type === 'light' ? logoLight : type === 'dark' ? logoDark : logoWithoutText }
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
            width={ width }
            alt='Go to the main page'
        />
    );
};

Logo.displayName = 'Logo';

Logo.propTypes = {
    mods: PropTypes.object,
    type: PropTypes.string,
    width: PropTypes.number
};

Logo.defaultProps = {
    mods: {},
    type: 'withoutText'
};

export default Logo;
