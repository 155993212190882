import useMods from '@hooks/useMods';
import bgLeft from '@images/get-on-traqq-layout-bg-1.svg';
import bgRight from '@images/get-on-traqq-layout-bg-2.svg';
import PropTypes from 'prop-types';
import React from 'react';

const GetOnTraqqBg = ({ mods, mix }) => {
    const { className } = useMods(GetOnTraqqBg, { mix, mods }, true);

    return (
        <div
            className={ className }
        >
            <div className='get-on-traqq-bg__left'>
                <img
                    src={ bgLeft }
                    alt='Ready to get on Traqq?'
                />
            </div>
            <div className='get-on-traqq-bg__right'>
                <img
                    src={ bgRight }
                    alt='Start track time'
                />
            </div>
        </div>
    );
};

GetOnTraqqBg.displayName = 'Get-On-Traqq-Bg';

GetOnTraqqBg.propTypes = {
    mods: PropTypes.object
};

GetOnTraqqBg.defaultProps = {
    mods: {}
};

export default GetOnTraqqBg;
