import { useRouter } from 'next/router';

const useUtmTags = (href, tags) => {

    if (!tags) return href;

    //utm tags should work only for these pages
    const activePages = [
        'time-tracking',
        'team-management',
        'team-reporting',
        'employee-monitoring',
        'attendance-tracking',
        'employee-productivity-tracking-software',
        'time-tracking-app-for-enterprises',
        'time-tracking-app-for-small-businesses',
        'time-tracking-app-for-agencies',
        'time-tracking-app-for-teams',
        'time-tracking-app-for-freelancers',
        'screen-monitoring',
        'updates'
    ];

    let url = false;

    try {
        url = new URL(href, process.env.NEXT_PUBLIC_APP_URL);
    } catch (e) {
        return href;
    }

    const router = useRouter();
    //const page = router.pathname.split('/').at(-1);//safari - bug
    const page = router.pathname.substring(router.pathname.lastIndexOf('/') + 1);

    if (!page || !activePages.includes(page)) return href;

    //we have the only value for utm_source for all active pages
    tags = {...tags, ...{utm_content: page ? page : 'home', utm_source: 'seo_productpage'}};

    Object.entries(tags).forEach(([key, value]) => {
        url.searchParams.append(key, value);
    });

    return url.toString();
};

export default useUtmTags;
