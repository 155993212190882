import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const Heading = ({ children, mods, mix, tag, color, className: classNameTail }) => {
    const { className } = useMods(Heading, { mix: { ...mix, tag }, mods: { ...mods, color } }, true);
    const TagName = tag;

    return (
        <TagName
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
        >
            { children }
        </TagName>
    );
};

Heading.displayName = 'Heading';

Heading.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    mix: PropTypes.object,
    mods: PropTypes.object,
    tag: PropTypes.string
};

Heading.defaultProps = {
    mix: {},
    mods: {},
    tag: 'h1'
};

export default Heading;
