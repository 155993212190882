import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const FooterCopyright = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(FooterCopyright, { mix, mods });

    return (
        <div
            className={ className + (classNameTail ? ' ' + classNameTail : '')  }
        >
            { children }
        </div>
    );
};

FooterCopyright.displayName = 'Footer-Copyright';

FooterCopyright.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

FooterCopyright.defaultProps = {
    mix: {},
    mods: {}
};

export default FooterCopyright;
