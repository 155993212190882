import css from '@utils/css';
import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const Info = ({ children, mods, mix, gap, tag, className: classNameTail }) => {
    const { className } = useMods(Info, { mix, mods: { ...mods, gap } });
    const TagName = tag;

    return (
        <TagName
            className={ css(className, classNameTail) }
        >
            { children }
        </TagName>
    );
};

Info.displayName = 'Info';

Info.propTypes = {
    children: PropTypes.node,
    gap: PropTypes.number,
    mix: PropTypes.object,
    mods: PropTypes.object,
    tag: PropTypes.string
};

Info.defaultProps = {
    mix: {},
    mods: {},
    tag: 'div'
};

export default Info;
