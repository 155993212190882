import css from '@utils/css';
import useMods from '@hooks/useMods';

import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const NavLink = ({ children, mods, mix, href, active, onClick, className: classNameTail }) => {
    const { className } = useMods(NavLink, { mix: { ...mix, active }, mods });

    const cn = css(className, classNameTail);

    return (
        href ? (
            <Link
                href={ href }
                prefetch={ false }
            >
                { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                <a
                    className={ cn }
                >
                    { children }
                </a>
            </Link>
        ) : (
            <button
                className={ cn }
                onClick={ onClick }
            >
                { children }
            </button>
        )
    );
};

NavLink.displayName = 'Nav-Link';

NavLink.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object,
    onClick: PropTypes.func,
    href: PropTypes.string.isRequired
};

NavLink.defaultProps = {
    mods: {}
};

export default NavLink;
