import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

import SwitchToTraqqBg from './SwitchToTraqqBg';
import SwitchToTraqqText from './SwitchToTraqqText';

const SwitchToTraqqWide = ({ mods, bg }) => {
    const { className } = useMods(SwitchToTraqqWide, { mods });

    return (
        <div
            className={ className }
        >
            <SwitchToTraqqBg
                mods={ { bg } }
            />
            <SwitchToTraqqText />
        </div>
    );
};

SwitchToTraqqWide.displayName = 'Switch-To-Traqq-Wide';

SwitchToTraqqWide.propTypes = {
    bg: PropTypes.string.isRequired,
    mods: PropTypes.object
};

SwitchToTraqqWide.defaultProps = {
    mods: {}
};

export default SwitchToTraqqWide;
