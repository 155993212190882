import css from '@utils/css';
import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const Nav = ({ children, mix, mods, direction, className: classNameTail }) => {
    const { className } = useMods(Nav, { mix, mods: { ...mods, direction } }, true);

    return (
        <ul
            className={ css(className, classNameTail) }
        >
            { children }
        </ul>
    );
};

Nav.displayName = 'Nav';

Nav.propTypes = {
    children: PropTypes.node,
    direction: PropTypes.string,
    mix: PropTypes.object,
    mods: PropTypes.object
};

Nav.defaultProps = {
    direction: 'col',
    mix: {},
    mods: {}
};

export default Nav;
