import css from '@utils/css';
import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(Container, { mix, mods });

    return (
        <div
            className={ css(className, classNameTail) }
        >
            { children }
        </div>
    );
};

Container.displayName = 'Container';

Container.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

Container.defaultProps = {
    mix: {},
    mods: {}
};

export default Container;
