import { DefaultSeoTraqq } from '@components/Seo';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

const Seo = ({ title, description, useTitleTemplate, additionalMetaTags, defaultSeoConfig: defaultSeoConfigProp }) => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];
    const canonical = `${ process.env.NEXT_PUBLIC_APP_URL }${ pathname !== '/' ? pathname : '' }`;

    const defaultSeoConfig = {
        title,
        description,
        canonical,
        norobots: true,
        titleTemplate: `%s ${ useTitleTemplate && '-' } ${ useTitleTemplate }`,
        openGraph: {
            images: [
                {
                    alt: description,
                    height: 630,
                    type: 'image/jpeg',
                    url: `${ process.env.NEXT_PUBLIC_APP_URL }/og-thumbnail.jpg`,
                    width: 1200
                }
            ],
            locale: 'en_US',
            site_name: 'Traqq | traqq.com',
            type: 'website'
        },
        additionalMetaTags,
        ...defaultSeoConfigProp
    };

    return (
        <>
            <DefaultSeoTraqq
                { ...defaultSeoConfig }
            />
        </>
    );
};

Seo.propTypes = {
    defaultSeoConfig: PropTypes.object,
    description: PropTypes.string,
    title: PropTypes.string,
    useTitleTemplate: PropTypes.string
};

Seo.defaultProps = {
    useTitleTemplate: 'Traqq'
};

export default Seo;
