import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';

const NavItem = ({ children, mods, onClick }) => {
    const { className } = useMods(NavItem, { mods }, true);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
        <li
            className={ className }
            onClick={ onClick }
        >
            { children }
        </li>
    );
};

NavItem.displayName = 'Nav-Item';

NavItem.propTypes = {
    children: PropTypes.node,
    mods: PropTypes.object,
    onClick: PropTypes.func
};

NavItem.defaultProps = {
    mods: {}
};

export default NavItem;
