import css from '@utils/css'
import useMods from '@hooks/useMods';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSVG from 'react-inlinesvg';

import { findColorByName, getIcon } from './IconsSvg';

const Icon = ({ mods, name, size, color, className: classNameTail }) => {
    const { className } = useMods(Icon, { mods });
    const [firstColor, secondColor] = findColorByName(color);
    const icon = getIcon(size, firstColor, secondColor);

    return (
        <i
            className={ css(className, classNameTail) }
        >
            <ReactSVG
                src={ icon[name] }
            />
        </i>
    );
};

Icon.displayName = 'Icon';

Icon.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    mods: PropTypes.object,
    name: PropTypes.string.isRequired,
    size: PropTypes.number
};

Icon.defaultProps = {
    mods: {},
    size: 32
};

export default Icon;
