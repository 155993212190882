import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const SwitchToTraqqWideBg = ({ mods }) => {
    const { className } = useMods(SwitchToTraqqWideBg, { mods }, true);

    return (
        <div
            className={ className }
        />
    );
};

SwitchToTraqqWideBg.displayName = 'Switch-To-Traqq-Wide__Bg';

SwitchToTraqqWideBg.propTypes = {
    mods: PropTypes.object
};

SwitchToTraqqWideBg.defaultProps = {
    mods: {}
};

export default SwitchToTraqqWideBg;
