import useMods from '@hooks/useMods';
import css from '@utils/css';
import PropTypes from 'prop-types';
import React from 'react';

const Paragraph = ({ children, mix, mods, tag, size, color, id, className: classNameTail }) => {
    const { className } = useMods(Paragraph, { mix, mods: { ...mods, color, size } }, true);
    const TagName = tag;

    return (
        <TagName
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
            id={ id }
        >
            { children }
        </TagName>
    );
};

Paragraph.displayName = 'Paragraph';

Paragraph.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    id: PropTypes.string,
    mix: PropTypes.object,
    mods: PropTypes.object,
    size: PropTypes.string,
    tag: PropTypes.string
};

Paragraph.defaultProps = {
    mix: {},
    mods: {},
    tag: 'p'
};

export default Paragraph;
