import css from '@utils/css';
import useMods from '@hooks/useMods';

import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ children, mods, mix, href, target, onClick, className: classNameTail, linkProps }) => {
    const { className } = useMods(Link, { mix, mods });

    return (
        <NextLink
            href={ href }
            prefetch={ false }
            onClick={ onClick }
        >
            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
            <a
                className={ css(className, classNameTail) }
                target={ target }
                { ...linkProps }
            >
                { children }
            </a>
        </NextLink>
    );
};

Link.displayName = 'Link';

Link.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    mix: PropTypes.object,
    mods: PropTypes.object,
    onClick: PropTypes.func,
    target: PropTypes.string
};

Link.defaultProps = {
    mods: {}
};

export default Link;
