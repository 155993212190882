import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const SectionHeadline = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(SectionHeadline, { mix, mods });

    return (
        <div
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
        >
            { children }
        </div>
    );
};

SectionHeadline.displayName = 'Section__Headline';

SectionHeadline.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

SectionHeadline.defaultProps = {
    mix: {},
    mods: {}
};

export default SectionHeadline;
